<template>
    <b-dropdown class="dropdown--secondary dropdown--secondary--users ml-auto" variant="link" toggle-class="text-decoration-none" no-caret>
        <template #button-content>
            <div class="section-users section-users--limited">
                <div class="section-users__images">
                    <div v-for="(avatar, index) in stringHelper.getAvatarsForDisplay(columnData)" :key="index" class="section-users__images__item">
                        <img :src="avatar" alt=""/>
                    </div>
                </div>

                <p>
                    <span class="section-users__data">
                        <a v-for="(item, index) in stringHelper.getPermissionNamesForDisplay(columnData).slice(0, 2)" :key="'1' + index">
                            <span v-if="index > 0">, </span>{{ item }}
                        </a>
                    </span>
                    <a v-for="(item, index) in stringHelper.getPermissionNamesForDisplay(columnData).slice(2, 3)" :key="'2' + index">
                        {{ item }}
                    </a>
                </p>
            </div>
        </template>

        <b-dropdown-header>
            <header class="dropdown__header-inner mb-0">
                <h4 class="heading4 mb-0">Permissions</h4>

                <a v-if="false" href="#" class="link link--lg">Edit</a>
            </header>

            <ul class="rows-list pb-0">
                <li  v-for="(permission, index) in columnData" :key="index">
                    <div class="rows-list__avatar">
                        <Avatar
                            :username="permission.name"
                            :src="permission.avatar"
                            :size="30">
                        </Avatar>
                    </div>

                    <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>

                    <!-- Please add here the logic first -->
                    <template v-if="typeof permission.role !== 'undefined'">
                        <p class="ml-auto">{{ permission.role.name }}</p>
                        <!--<a class="link-info" href="#">
                            <span class="link-info__content">{{ permission.role.description }}</span>
                        </a>-->
                        <a class="link-info" v-b-popover.hover.top="permission.role.description" title="" hideArrow={true}></a>
                    </template>
                </li>
            </ul>
        </b-dropdown-header>
    </b-dropdown>
</template>

<script>

import Avatar from "vue-avatar";

export default {
    props: {
        rowId: Number,
        columnData: Array,
    },

    components: {
        Avatar
    }
}
</script>

<style scoped>
    .link-info {
        padding: 0;
        background-color: transparent !important;
        border: none;
    }
    .popover {
        top: 50px !important;
        left: auto !important;
        margin: auto !important;
        max-width: 160px !important;
        padding: 5px 10px;
        background: #fff;
        border-radius: 4px;
        color: #7b7b7b !important;
        filter: drop-shadow(1px 3px 4px rgba(#000, 0.3));
        box-shadow: none !important;
        font-size: 10px !important;
        font-weight: 400;
        line-height: 1.5;
    }
    .popover.bs-popover-bottom,
	.popover.bs-popover-top {
		margin-left: 0 !important;
        left: 0 !important;
	}
    .popover.bs-popover-bottom .arrow {
        display: none !important;
        opacity: 0;
        visibility: hidden !important;
    }
    div.popover.b-popover .arrow {display: none !important; visibility:hidden !important;}
</style>